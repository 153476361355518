<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <div v-show="show" class="alert alert-danger alert-dismissible fade show" role="alert">
            <span v-for="(err, index) in validation_errors" :key="index">{{ err[0] }} <br/></span>
          </div>
          <form action="" enctype="multipart/form-data" files="true" role="form">
            <div class="form-row">
              <div class="col-md-12 col-sm-12 col-lg-12">
                <label for="purpose" class="col-form-label col-form-label-sm">Remarks</label>
                <textarea v-model="purpose" id="purpose" name="purpose" class="form-control form-control-sm" placeholder="Enter remarks"></textarea>
              </div>
            </div>
            <div class="form-row">
              <div class="col-md-6 col-sm-6 col-lg-6">
                <label for="fuel_purpose_type_id" class="col-form-label col-form-label-sm">Fuel Purpose Type <span class="custom-required">*</span></label>
                <select v-model="fuel_purpose_type_id" v-validate="'required'" id="fuel_purpose_type_id" name="fuel_purpose_type_id" class="form-control form-control-sm">
                  <option selected disabled :value="''">Select fuel purpose type </option>
                  <option v-for="(fuel_purpose_type, index) in fuel_purpose_types" :key="index" :value="fuel_purpose_type.id">{{ fuel_purpose_type.fuel_purpose_type_desc }}</option>
                </select>
                <div class="invalid-feedback">Fuel purpose type is required</div>
              </div>
              <div class="col-md-6 col-sm-6 col-lg-6">
                <label for="requisite_liter" class="col-form-label col-form-label-sm">Requisite Liter <span class="custom-required">*</span></label>
                <input type="text" v-model="requisite_liter" v-validate="{ required: true, max: 7, regex: /^[0-9]*(?:\.[0-9]{0,2})?$/ }" id="requisite_liter" name="requisite_liter" class="form-control form-control-sm" placeholder="Enter requisite liter"/>
                <div class="invalid-feedback">Requisite liter is required</div>
              </div>
            </div>
            <div class="form-row">
              <div class="col-md-6 col-sm-6 col-lg-6">
                <label for="last_refill_date" class="col-form-label col-form-label-sm">Last Refill Date <span class="custom-required">*</span></label>
                <datepicker v-model="last_refill_date" v-validate="'required'" id="last_refill_date" name="last_refill_date" :format="customLastRefillDate" :disabled-dates="disabledDates" placeholder="Last refill date" ></datepicker>
                <div class="text-danger" style="margin-top: 0.25rem; font-size: 0.75rem;" v-if="errors.has('last_refill_date')">last refill date is required</div>
              </div>
              <div class="col-md-6 col-sm-6 col-lg-6">
                <label for="fuel_type_id" class="col-form-label col-form-label-sm">Fuel Type <span class="custom-required">*</span></label>
                <select v-model="fuel_type_id" v-validate="'required'" id="fuel_type_id" name="fuel_type_id" class="form-control form-control-sm">
                  <option selected disabled :value="''">Select fuel type </option>
                  <option v-for="(fuel_type, index) in fuel_types" :key="index" :value="fuel_type.id">{{ fuel_type.fuel_type_desc }}</option>
                </select>
                <div class="invalid-feedback">Fuel type  is required</div>
              </div>
            </div>
            <div class="form-row">
              <div class="col-md-6 col-sm-6 col-lg-6">
                <label for="present_running_hour" class="col-form-label col-form-label-sm">Present Running Hour <span class="custom-required">*</span></label>
                <input type="text" v-model="present_running_hour" v-validate="{ required: true, max: 7, regex: /^[0-9]*(?:\.[0-9]{0,2})?$/ }" id="present_running_hour" name="present_running_hour" class="form-control form-control-sm" placeholder="Enter present running hour"/>
                <div class="invalid-feedback">Present running hour is invalid</div>
              </div>
              <div class="col-md-6 col-sm-6 col-lg-6">
                <label for="hour_ran_during_last_refill" class="col-form-label col-form-label-sm">Hour Ran During Last Refill <span class="custom-required">*</span></label>
                <input type="text" v-model="hour_ran_during_last_refill" v-validate="{ required: true, max: 7, regex: /^[0-9]*(?:\.[0-9]{0,2})?$/ }" id="hour_ran_during_last_refill" name="hour_ran_during_last_refill" class="form-control form-control-sm" placeholder="Enter hour ran during last refill"/>
                <div class="invalid-feedback">Hour ran during last refill is invalid</div>
              </div>
            </div>
            <div class="form-row">
              <div class="col-md-6 col-sm-6 col-lg-6">
                <label for="generator_reading" class="col-form-label col-form-label-sm">Generator Reading <span style="font-weight: 100;">*Previous file <a :href="generator_reading_url" target="_blank">click here</a></span></label>
                <input type="file" ref="generatorReading" id="generator_reading" name="generator_reading" @change="getGeneratorReading()" accept=".jpg,.jpeg,.png" class="form-control form-control-sm"/>
              </div>
              <div class="col-md-6 col-sm-6 col-lg-6">
                <label for="fuel_refilling" class="col-form-label col-form-label-sm">Fuel Refilling <span style="font-weight: 100;">*Previous file <a :href="fuel_refilling_url" target="_blank">click here</a></span></label>
                <input type="file" ref="fuelRefilling" id="fuel_refilling" name="fuel_refilling" @change="getFuelRefilling()" accept=".jpg,.jpeg,.png" class="form-control form-control-sm"/>
              </div>
            </div>
            <a-button class="btn btn-success waves-effect waves-light mt-3" :loading="updateRequisition" @click.prevent="update"><i class="fa fa-check mr-1" aria-hidden="true"></i>Update Fuel Requisition and Approve</a-button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apiClient from '@/services/axios'
import moment from 'moment'
import Datepicker from 'vuejs-datepicker'

export default {
  name: 'update',
  components: { Datepicker },
  data() {
    return {
      fuel_types: [],
      fuel_purpose_types: [],
      purpose: '',
      requisite_liter: '',
      last_refill_date: '',
      present_running_hour: '',
      hour_ran_during_last_refill: '',
      generator_reading: '',
      fuel_refilling: '',
      fuel_type_id: '',
      fuel_purpose_type_id: '',
      generator_reading_url: '',
      fuel_refilling_url: '',
      validation_errors: {},
      show: false,
      updateRequisition: false,
      disabledDates: {
        from: new Date(Date.now()),
      },
    }
  },
  mounted() {
    this.getCode()
    this.onUpdate()
  },
  methods: {
    getCode() {
      apiClient.get('api/hub/fuel-requisition/codes')
        .then(response => {
          const data = response.data
          this.fuel_types = data.fuel_types
          this.fuel_purpose_types = data.fuel_purpose_types
        })
        .catch(error => {
          console.log(error)
        })
    },
    onUpdate() {
      const fuelRequisitionId = this.$route.params.fuel_requisition_id
      this.loading = true
      apiClient.get('api/hub/fuel-requisitions/edit/' + fuelRequisitionId)
        .then(response => {
          this.loading = false
          if (!response.data.error) {
            const data = response.data.requisition
            this.purpose = data.purpose
            this.requisite_liter = data.requisite_liter
            this.last_refill_date = data.last_refill_date
            this.present_running_hour = data.present_running_hour
            this.hour_ran_during_last_refill = data.hour_ran_during_last_refill
            this.fuel_type_id = data.fuel_type_id
            this.fuel_purpose_type_id = data.fuel_purpose_type_id
            this.generator_reading_url = data.generator_reading_url
            this.fuel_refilling_url = data.fuel_refilling_url
          } else {
            this.$notification.error({
              message: response.data.message,
            })
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    customLastRefillDate(date) {
      this.last_refill_date = moment(date).format('YYYY-MM-DD')
      return moment(date).format('YYYY-MM-DD')
    },
    getGeneratorReading() {
      this.generator_reading = this.$refs.generatorReading.files[0]
      if (this.generator_reading.size > 3072000) {
        this.$notification.error({
          message: 'File size not greater then 3 MB',
        })
        this.generator_reading = ''
      }
    },
    getFuelRefilling() {
      this.fuel_refilling = this.$refs.fuelRefilling.files[0]
      if (this.fuel_refilling.size > 3072000) {
        this.$notification.error({
          message: 'File size not greater then 3 MB',
        })
        this.fuel_refilling = ''
      }
    },
    update() {
      this.$validator.validate().then(valid => {
        if (valid) {
          this.updateRequisition = true
          const formData = new FormData()
          formData.append('purpose', this.purpose)
          formData.append('requisite_liter', this.requisite_liter)
          formData.append('last_refill_date', this.last_refill_date)
          formData.append('present_running_hour', this.present_running_hour)
          formData.append('hour_ran_during_last_refill', this.hour_ran_during_last_refill)
          formData.append('generator_reading', this.generator_reading)
          formData.append('fuel_refilling', this.fuel_refilling)
          formData.append('fuel_type_id', this.fuel_type_id)
          formData.append('fuel_purpose_type_id', this.fuel_purpose_type_id)
          const fuelRequisitionId = this.$route.params.fuel_requisition_id
          apiClient.post('api/hub/fuel-requisitions/update/' + fuelRequisitionId, formData, { headers: { 'Content-Type': 'multipart/form-data', 'Content-type': 'application/json' } })
            .then(response => {
              if (!response.data.error) {
                this.$notification.success({
                  message: response.data.message,
                })
                this.updateRequisition = false
                this.$router.push({ name: 'adminHubFuelPendingRequisitionHistory' })
              } else {
                this.updateRequisition = false
                this.$notification.error({
                  message: response.data.message,
                })
              }
            }).catch(error => {
              this.updateRequisition = false
              this.show = true
              this.validation_errors = error.response.data.errors
              this.hide()
            })
        }
      })
    },
    hide() {
      setTimeout(() => {
        this.show = false
      }, 5000)
    },
  },
}
</script>

<style scoped>

</style>
